import Img, { FluidObject } from 'gatsby-image'
import * as React from 'react'
import { useRef } from 'react'
import Slider from 'react-slick'
import styled, { css, CSSProp } from 'styled-components'
import { ProdCarousselH2, Subheadings,WholesaleH3, WholesaleP } from 'styles/base'

import { getRegionalLink, isJapanese } from '../../helpers/locale'
import Arrow from '../../images/svg/arrow-right.svg'
import BodyCopy from '../atoms/bodycopy'
import { Category } from '../atoms/category'
import Icon from '../atoms/icon'
import Link from '../atoms/link'
import { PlaceholderImage } from '../atoms/placeholderImage'
import Container from '../container'

const StyledCarousel = styled.div`
  ${({ theme }): CSSProp => css`
    position: relative;
    overflow: hidden;

    .row {
      display: flex;
      @media only screen and ${theme.breakpoints.toMediumScreen} {
        flex-direction: column;
      }
    }

    .fade {
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 30rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        background: linear-gradient(
          to left,
          ${theme.colours.secondary},
          rgb(239, 231, 221, 0)
        );
      }

      button {
        pointer-events: all;
        transition: ${theme.animation.defaultLength} ease;
        opacity: 0;
        background-color: ${theme.colours.tertiary};
        color: ${theme.colours.light};
        border: none;
        cursor: pointer;
        outline: none;
        width: 8rem;
        height: 10rem;
        @media only screen and ${theme.breakpoints.toLargeScreen} {
          opacity: 1;
          height: 7rem;
          width: 6rem;
        }
      }
    }

    &:hover .fade button {
      @media only screen and ${theme.breakpoints
          .fromLargeScreen} and (pointer: fine) {
        opacity: 1;
      }
    }
  `}
`

const Intro = styled.div`
  ${({ theme }): CSSProp => css`
    padding-right: 3rem;
    position: relative;
    z-index: 2;

    @media only screen and ${theme.breakpoints.toMediumScreen} {
      margin-bottom: 4rem;
    }
    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      padding-top: 5rem;
    }
    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      padding-right: 10rem;
    }

    div {
      font-weight: ${theme.font.fontWeightLight};
      max-width: 39rem;
      font-size: ${theme.font.fontSizeMid};
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        font-size: ${theme.font.fontSizeBodyDefault};
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        font-size: ${theme.font.fontSizeBodyLarge};
      }
    }

    a {
      display: inline-block;
      margin-top: 2.2rem;
      font-size: ${theme.font.fontSizeSmall};
      text-transform: uppercase;
      letter-spacing: 1.2px;
      font-weight: bold;

      &:hover {
        color: ${theme.colours.tertiary};
      }
    }

    .icon {
      color: ${theme.colours.tertiary};
      margin-right: 1rem;
    }
  `}
`

const Items = styled.div<{ type?: 'card' }>`
  ${({ type, theme }): CSSProp => css`
    position: relative;
    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      width: calc(100% - 35rem);
    }
    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      &:before {
        content: '';
        background: rgb(239, 231, 221);
        background: linear-gradient(
          90deg,
          rgba(239, 231, 221, 1) 0%,
          rgba(239, 231, 221, 1) 0%,
          rgba(239, 231, 221, 1) 99%,
          rgba(239, 231, 221, 0) 100%
        );
        position: absolute;
        z-index: 1;
        top: 0;
        right: 100%;
        height: 100%;
        width: 100vw;
      }
    }

    .slick-slider {
      max-width: 29rem;
      @media only screen and ${theme.breakpoints.fromXLargeScreen} {
        max-width: 42.5rem;
      }

      ${type !== 'card' &&
        css`
          max-width: 42.5rem;
        `}
    }

    .slick-list {
      overflow: visible;
    }

    .slick-slide {
      padding: 3rem 0;
    }

    .slick-prev {
      display: none !important;
    }

    .slick-next {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  `}
`

const Item = styled.div`
  ${({ theme }): CSSProp => css`
    padding: 0 2rem 0 0;
    outline: none;
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      padding-right: 3rem;
    }

    .gatsby-image-wrapper {
      margin-bottom: 1.6rem;
    }

    a {
      text-decoration: none;
    }

    .title {
      font-weight: ${theme.font.fontWeightLight};
    }
  `}
`

const Card = styled(Link)`
  ${({ theme }): CSSProp => css`
    outline: none;
    text-decoration: none;
    position: relative;
    box-shadow: 0 5px 0 0 rgba(43, 25, 22, 0);
    transition: all 0.3s ease-in-out;

    .card-inner {
      padding: 2rem;
    }

    &::after {
      content: '';
      top: 0;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 5px;
      box-shadow: 0 5px 21px 0 rgba(43, 25, 22, 0.12);
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      &::after {
        @media only screen and ${theme.breakpoints.fromLargeScreen} {
          opacity: 1;
        }
      }

      .gatsby-image-wrapper img {
        transform: scale(1);
      }
    }

    .gatsby-image-wrapper {
      margin-bottom: 1.6rem;
    }

    .subtitle {
      font-size: ${theme.font.fontSizeBodyMobile};
      font-weight: 300;
    }

    .title {
      ${isJapanese() ?
        css`
          font-family: ${ theme.font.fontFamilyDefault };
          font-weight: ${ theme.font.fontWeightBold };
        `
        : css`font-family: ${ theme.font.fontFamilyAlt };`
      };
      font-size: ${theme.font.fontSizeBodyDefault};
      margin-bottom: 1rem;
    }
  `}
`

const StyledPlaceholder = styled(PlaceholderImage)`
  padding-bottom: 66.66%;
`

const WholesaleCarousel = ({
  intro,
  introCta,
  items,
  type,
  linkPrefix = 'community',
}: {
  intro: React.ReactNode
  introCta?: { to: string; text: React.ReactNode }
  items: [
    {
      id: string
      title: string
      subtitle: string
      link: string
      slug: string
      category: {
        title: string
        slug: string
      }
      heroImage: {
        fluid: FluidObject
        title: string
      }
      image: {
        fluid: FluidObject
        title: string
      }
    }
  ]
  type?: 'card'
  linkPrefix?: string
}): JSX.Element => {
  const slider = useRef<Slider>()
  const nextSlide = (): void => {
    slider?.current?.slickNext()
  }

  return (
    <StyledCarousel>
      <Container>
        <div className="row">
          <Intro>
            <BodyCopy><WholesaleP>{intro}</WholesaleP></BodyCopy>
            {introCta && (
              <Link to={introCta.to}>
                <Icon className="icon" size="small">
                  <Arrow />
                </Icon>
                {introCta.text}
              </Link>
            )}
          </Intro>
          <Items type={type}>
            <Slider ref={slider} arrows={false}>
              {items?.map(item => {
                if (type === 'card') {
                  
                  return (
                    <Card key={item.id} to={getRegionalLink(item.link)}>
                      <div className="card-inner">
                        <div>
                          <Img
                            fluid={[
                              { ...item.image.fluid, aspectRatio: 1.5 },
                            ]}
                            alt={item.image.title}
                            imgStyle={{ objectFit: 'contain' }}
                          />
                        </div>
                        <br />
                        <div className="title"><ProdCarousselH2>{item.title}</ProdCarousselH2></div>
                        <div className="subtitle"><WholesaleP>{item.subtitle}</WholesaleP></div>
                      </div>
                    </Card>
                  )
                }

                return (
                  <Item key={item.id}>
                    <Link to={`/${linkPrefix}/${item.slug}/`}>
                      {item.heroImage ? (
                        <Img
                          fluid={{
                            ...item.heroImage?.fluid,
                            aspectRatio: 1.5,
                          }}
                          alt={item.heroImage?.title}
                        />
                      ) : (
                        <StyledPlaceholder />
                      )}
                    </Link>
                    {item?.category?.slug ? (
                      <Category
                        className="category"
                        to={`/${linkPrefix}/category/${item.category.slug}/`}
                      >
                        <Subheadings>{item.category.title}</Subheadings>
                      </Category>
                    ) : item?.category?.title && (
                      <Category className="category" as="span">
                        <Subheadings>{item.category.title}</Subheadings>
                      </Category>
                    )}
                  
                    <Link
                      className="title"
                      to={`/${linkPrefix}/${item.slug}/`}
                    >
                      <WholesaleH3>{item.title}</WholesaleH3>
                    </Link>
                  </Item>
                )
              })}
            </Slider>
          </Items>
        </div>
      </Container>

      <div className="fade">
        <button onClick={(): void => nextSlide()}>
          <Icon>
            <Arrow />
          </Icon>
        </button>
      </div>
    </StyledCarousel>
  )
}

export default WholesaleCarousel
